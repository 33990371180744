import React from 'react';
import PropTypes from 'prop-types';
import { WrapperStyled, WrapperBgStyled } from './styles';
import Container from './Container';
import Row from './Row';
import Col from './Col';
import Box from './Box';

const Section = ({ children, tag, ...props }) => {
  const { fullwidth, bgImage, ...wrapperStyle } = props;

  if (bgImage) {
    return (
      <WrapperBgStyled {...wrapperStyle} tag={tag} fluid={bgImage}>
        <Container fluid={fullwidth}>{children}</Container>
      </WrapperBgStyled>
    );
  }
  return (
    <WrapperStyled as={tag} {...wrapperStyle}>
      <Container fluid={fullwidth}>{children}</Container>
    </WrapperStyled>
  );
};

export { Container, Row, Col, Box };

Section.propTypes = {
  children: PropTypes.node.isRequired,
  fullwidth: PropTypes.bool,
  pt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pl: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ml: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  responsive: PropTypes.object,
};

Section.defaultProps = {
  fullwidth: null,
  pt: null,
  pl: null,
  pb: null,
  pr: null,
  mt: null,
  mb: null,
  ml: null,
  mr: null,
  responsive: null,
};

export default Section;

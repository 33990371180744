import React from 'react';
import PropTypes from 'prop-types';
import { Boxwrap } from './styles';

const Box = ({ children, ...restProps }) => {
  return <Boxwrap {...restProps}>{children}</Boxwrap>;
};

Box.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Box;
